:root {
  font-size: 16px;
}

@media only screen and (max-width: 1280px) {
  :root {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1024px), screen and (max-height: 768px) {
  :root {
    font-size: 14px;
  }
}

@media only screen and (max-width: 968px) and (max-height: 680px) {
  :root {
    font-size: 12px;
  }
}

@media only screen and (max-width: 968px), screen and (max-height: 580px) {
  :root {
    font-size: 10px;
  }
}

@media only screen and (max-width: 600px) {
  :root {
    font-size: 10px;
  }
}

@media only screen and (max-width: 480px) and (max-height: 940px) {
  :root {
    font-size: 10px;
  }
}

@media only screen and (max-width: 480px) and (max-height: 600px) {
  :root {
    font-size: 9px;
  }
}

@media only screen and (max-height: 568px) {
  :root {
    font-size: 9px;
  }
}

html {
  min-width: 412px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body::-webkit-scrollbar{
  display: none;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
